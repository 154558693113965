import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import logo from '../../static/images/logo.png';

export default () => (
  // <nav role="navigation">
  //   <ul className={styles.navigation}>
  //     <li className={styles.navigationItem}>
  //       <Link to="/">Home</Link>
  //     </li>
  //     <li className={styles.navigationItem}>
  //       <Link to="/blog/">Blog</Link>
  //     </li>
  //   </ul>
  // </nav>

  // <header id="header">
  <Container>
    <Navbar expand="lg" fixed="top" className={styles.navBar}>
      <Navbar.Brand >
        <Link to="/"><img className={styles.logo} src={logo} alt="logo" /></Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" >
        <Nav defaultActiveKey="#home" className={styles.list}>
          <Link to="/#home">Home</Link>
          <Link to="/#interests">Interests</Link>
          <Link to="/#myphoto">Photos</Link>
          <Link to="/#aboutme">About</Link>
          <Link to="/#get-in-touch">Contact</Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Container>
)
